import { createSharedComposable, useStorage } from '@vueuse/core';
import { useLicenceStore } from "~/store/useLicenceStore";
import { useShopStore } from '~/store/useShopStore';

function normalizeTitle(title: string): string {
    return title
    .normalize("NFD") // Normaliser pour décomposer les accents
    .replace(/[\u0300-\u036f]/g, "") // Supprimer les marques diacritiques
    .replace(/[^a-zA-Z0-9 ]/g, "") // Supprimer les caractères non alphanumériques
    .trim() // Supprimer les espaces en début et fin de chaîne
    .replace(/\s+/g, "_") // Remplacer les espaces par des tirets
    .toLowerCase(); // Convertir en minuscules
}

const _useUIApp = () => {
    // Récupération des catégories de produits
    
    const { isDevis } = useLicenceStore();
    const { establishment, links } = useAppConfig();
    
    const shopStore = useShopStore()
    const { groups } = storeToRefs(shopStore)

    
    // Création dynamique des sous-catégories de la boutique
    const shopSubCategories = groups.value.map((category, index) => ({
        idSubCategory: index + 1, 
        nameSubCategory: category.title,
        linkSubCategory: `${links.shop}/${normalizeTitle(category.title)}-${category.id}`, 
        disabled: !category.title // 
    }));

    const navbarApp = reactive([
        {
            idCategory: 1,
            nameCategory: "Tableau de bord",
            imageCategory: "dashboard",
            fontAwesome: "",
            link: links.dashboard,
            disabled: false,
            subCategories: [],
        },
        {
            idCategory: 2,
            nameCategory: "Mon activité",
            imageCategory: "pencil",
            fontAwesome: "",
            link: "/activite",
            subCategories: [
                {
                    idSubCategory: 3,
                    nameSubCategory: "Mes véhicules",
                    linkSubCategory: links.vehicle,

                },
                {
                    idSubCategory: 4,
                    nameSubCategory: "Mes commandes",
                    linkSubCategory: links.orders,
                },
                {
                    idSubCategory: 5,
                    nameSubCategory: "Mes devis",
                    linkSubCategory: links.devisHistory,
                    disabled: !isDevis(establishment.configuration.isDevis)
                },
                {
                    idSubCategory: 6,
                    nameSubCategory: "Mes clients",
                    linkSubCategory: links.ListCustomer,
                    disabled: false
                },
                {
                    idSubCategory: 7,
                    nameSubCategory: "Mes rendez-vous",
                    linkSubCategory: "",
                    disabled: true

                }
            ]
        },
        {
            idCategory: 8,
            nameCategory: "Catalogue",
            imageCategory: "catalog",
            alternativeImageCategory: "",
            link: links.catalog,
            isVehicleNeeded: true,
            subCategories: []
        },
        {
            idCategory: 9,
            nameCategory: "Boutique",
            imageCategory: "shop",
            alternativeImageCategory: "",
            link: links.shop,
            disabled: !establishment.configuration.isShop,
            subCategories: !establishment.configuration.isShop ? [] : shopSubCategories,            
        },
        {
            idCategory: 10,
            nameCategory: "Pneumatique",
            imageCategory: "tire",
            alternativeImageCategory: "",
            link: links.tires,
            disabled: !establishment.configuration.isPneumatic,

            subCategories: [],
        },
        {
            idCategory: 11,
            nameCategory: "Gestion atelier",
            imageCategory: "clede10",
            alternativeImageCategory: "",
            link: "/gestion-atelier",
            disabled: true,
            subCategories: [
                {
                    idSubCategory: 12,
                    nameSubCategory: "Planning rendez-vous",
                    linkSubCategory: "",
                    disabled: true

                },
                {
                    idSubCategory: 13,
                    nameSubCategory: "Ordre de mission",
                    linkSubCategory: "",
                    disabled: true

                },
                {
                    idSubCategory: 14,
                    nameSubCategory: "Suivi heures facturées",
                    linkSubCategory: "",
                    disabled: true

                }
            ],
        },
        {
            idCategory: 15,
            nameCategory: "Mon site internet",
            imageCategory: "fa-regular-home",
            alternativeImageCategory: "",
            link: "/my-site",
            disabled: true,
            subCategories: [
                {
                    idSubCategory: 16,
                    nameSubCategory: "Actualités",
                    linkSubCategory: "",
                    disabled: true

                },
                {
                    idSubCategory: 17,
                    nameSubCategory: "Promotions",
                    linkSubCategory: "",
                    disabled: true

                },
                {
                    idSubCategory: 18,
                    nameSubCategory: "Diaporama",
                    linkSubCategory: "",
                    disabled: true

                },
                {
                    idSubCategory: 19,
                    nameSubCategory: "Véhicules d’occasion",
                    linkSubCategory: " ",
                    disabled: true

                },
                {
                    idSubCategory: 20,
                    nameSubCategory: "Recrutement",
                    linkSubCategory: "",
                    disabled: true

                }
            ],
        },
        {
            idCategory: 21,
            nameCategory: "Statistiques",
            imageCategory: "circle-plus",
            alternativeImageCategory: "",
            link: "/stats",
            disabled: true,
            subCategories: [
                {
                    idSubCategory: 22,
                    nameSubCategory: "Plaques",
                    linkSubCategory: "",
                    disabled: true

                },

            ],
        },
    ])

    return {
        navbarApp
    };
}

export const useUIApp = createSharedComposable(_useUIApp)